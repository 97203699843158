import { defineStore } from 'pinia'

// breadcrumb TEMPLATE :
// {
//     text: 'Dashboard',
//     to: {
//         name: 'providers.show',
//         params: {
//             id: 3
//         },
//     },
//     // disabled: false,
//     exact: true,
// }

export const useBreadcrumbsStore = defineStore('breadcrumbs', {
    state: () => {
        return {
            breadcrumbs: [
                {
                    text: 'Events',
                    to: {
                        name: 'events.index'
                    },
                    exact: true,
                },
            ],
        }
    },
    actions: {
        addBreadcrumb(crumb, items) {
            let i = this.breadcrumbs.findIndex((el) => (el.to.name === crumb.to.name))

            if (i >= 0)
                this.breadcrumbs.splice(i);


            /*

            console.log('crumb', crumb);
            //pensare di poter aggiungere voci di breadcrum con navigazione

            var obj = [
                {
                    text: 'voce1',
                    to: {
                        name: 'participant_type.index'
                    },
                },
                {
                    text: 'voce2',
                    to: {
                        name: 'participant_type.index'
                    },
                }
            ];

            obj.forEach(element => this.breadcrumbs.push({ ...element, exact: true }))

            this.breadcrumbs.push({ ...obj[0], exact: true })
            */


            this.breadcrumbs.push({ ...crumb, exact: true })
        },
        reset() {
            this.breadcrumbs = [
                {
                    text: 'Events',
                    to: {
                        name: 'events.index'
                    },
                    exact: true,
                },
            ]
        },
    }
})