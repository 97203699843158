import Vue from 'vue'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// OR with vuetify-loader
// import Vuetify from 'vuetify/lib'


Vue.use(Vuetify)

const opts = {}

export default new Vuetify(opts)