import { useUserStore } from "@stores/user";
import { useBreadcrumbsStore } from "@stores/breadcrumbs";

import VueRouter from "vue-router";
import routes from './routes';

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/"
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const reqAuth = to.matched.some((record) => record.meta.requiresAuth);
  const authUser = userStore.authUser();

  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };

  if ( !reqAuth ) {
    next();
  } else {
    // se necessita autenticazione....
    if (!authUser) {
      next(loginQuery);
    } else {
      next();
    }
  }
  
  // if (to.meta.requiresAuth && !userStore.isLoggedIn) return '/login'
  // next();
});

router.beforeEach((to, from, next) => {
    if (to.path.match('login') /*|| to.name === 'authHome'*/)
        next();
    else {
        const breadcrumbsStore = useBreadcrumbsStore();

        if (to.meta.breadcrumb?.is_root)
            breadcrumbsStore.reset()

        breadcrumbsStore.addBreadcrumb({
            text: to.meta.breadcrumb?.name ?? to.name,
            to: {
                name: to.name,
                params: { ...to.params },
            },
        });

        next();
    }

    
});

    export default router;