export default [
    {
        path: "/templates",
        name: "template",
        meta: { requiresAuth: true },
        component: () => import('@views/templates/Main.vue'),
        children: [
            {
                path: "",
                name: "templates.index",
                meta: { requiresAuth: true, breadcrumb: { name: 'Templates' }, is_root: true },
                component: () => import('@views/templates/Index.vue'), 
            },
            /*{
                path: "/templates/:id/show",
                name: "templates.show",
                props: true,
                meta: { requiresAuth: true },
                component: () => import('@views/templates/Show.vue'), 
            },
            {
                path: "/templates/add",
                name: "templates.add",
                props: true,
                meta: { requiresAuth: true , breadcrumb: { name: 'Add Template' }, is_root: true},
                component: () => import('@views/templates/Edit.vue'),
            },
            {
                path: "/templates/:id/edit/:template_id?",
                name: "template.edit",
                props: true,
                meta: { requiresAuth: true , breadcrumb: { name: 'Edit Template' } , is_root: true},
                component: () => import('@views/templates/Edit.vue'),
            }*/
        ]
    },
]