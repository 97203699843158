export default [
    {
        path: "/providers",
        name: "providers",
        meta: { requiresAuth: true },
        component: () => import('@views/providers/Main.vue'),
        children: [
            {
                path: "",
                name: "providers.index",
                meta: { requiresAuth: true, breadcrumb: { name: 'Provider List', is_root: true } },
                component: () => import('@views/providers/Index.vue'), 
            },
            {
                path: "/providers/:id",
                name: "providers.show",
                props: route => ({ id: route.params.id, readonly: true, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Provider' } },
                component: () => import('@views/providers/Edit.vue'), 
            },
            {
                path: "/providers/:id?/edit",
                name: "providers.edit",
                props: route => ({ id: route.params.id, readonly: false, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit Provider' } },
                component: () => import('@views/providers/Edit.vue'),
            },
        ]
    },
]