/*
 * This is the initial API interface
 * we set the base URL for the API
 ? Both request & response are logged to the console.
 ! Remove the console logs for production.
*/
import { useUserStore } from "@stores/user";
import axios from "axios";

const authClient = axios.create({
  baseURL: window.location.origin,
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const userStore = useUserStore();
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 419) &&
      userStore.user
    ) {
      userStore.logout();
    }
    return Promise.reject(error);
  }
);

export default authClient;