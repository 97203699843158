export default [
    {
        path: "/gates",
        name: "gates",
        meta: { requiresAuth: true },
        component: () => import('@views/gates/Main.vue'),
        children: [
            {
                path: "",
                name: "gates.index",
                meta: { requiresAuth: true, breadcrumb: { name: 'Gate List', is_root: true } },
                component: () => import('@views/gates/Index.vue'), 
            },
            {
                path: "/gates/:id",
                name: "gates.show",
                props: true,
                meta: { requiresAuth: true, breadcrumb: { name: 'Gate' } },
                component: () => import('@views/gates/Show.vue'), 
            },
            {
                path: "/gates/:id?/edit",
                name: "gates.edit",
                props: true,
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit Gate' } },
                component: () => import('@views/gates/Edit.vue'),
            },
        ]
    },
]