import apiClient from "@services/apiClient";

function buildQueryParams(params) {
    let os = ''
    for (let k in params) {
        os += `${k}=${encodeURIComponent(params[k])}&`
    }
    
    return os
}


function GenericApiResource(endpoint_resource, exceptions = []) {

    async function _get(id, options = {}) {
        let uri = `/${endpoint_resource}`;

        if (id)
            uri += `/${id}`
        // console.log('PARAMS MEDIUM', options)

        // let query_params = buildQueryParams(options)

        // console.log('PARAMS AFTER', query_params)
        // if (query_params.length > 0) {
        //     uri += '?' + query_params;
        // }

        // return apiClient.get(uri);
        let response = await apiClient.get(uri, { params: options });

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    }

    async function _save(item) {
        let prom = undefined;
        let expected_status = undefined;

        if (item.id) {
            prom = apiClient.put(`/${endpoint_resource}/${item.id}`, item);
            expected_status = 200;
        }
        else {
            prom = apiClient.post(`/${endpoint_resource}`, item);
            expected_status = 201;
        }

        let response = await prom;

        if (response.status === expected_status)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    }

    async function _delete(id) {
        let response = await apiClient.delete(`/${endpoint_resource}/${id}`);

        if (response.status === 204)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    }

    let _interface = {
        get: _get,
        save: _save,
        delete: _delete,
    }

    for (let i in _interface) {
        if (exceptions.includes(i))
            delete _interface[i]
    }

    return _interface
}

function GenericApiRelationship({ resource, relation }, exceptions = []) {

    const buildApiResourceName = (name) => {
        let outcome = [];

        for (let o of name.split('_')) {
            let capitalized = o.charAt(0).toUpperCase() + o.slice(1);
            outcome.push(capitalized);
        }

        return outcome.join('');
    }

    // let relation_capitalized = relation.charAt(0).toUpperCase() + relation.slice(1);
    let relation_capitalized = buildApiResourceName(relation);
    let relation_unpluralized = relation_capitalized.substr(0, relation_capitalized.length - 1);

    const respond = (response, expected_status) => {
        if (response.status === expected_status)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    };

    // async function _get(resource_id, relation_id = undefined, options = {}) {
    async function _get({ resource_id, relation_id, query_params }) {
        let uri = `/${resource}/${resource_id}/${relation}`;

        if (relation_id)
            uri += `/${relation_id}`

        query_params = buildQueryParams(query_params)

        if (query_params.length > 0) {
            uri += '?' + query_params;
        }

        let response = await apiClient.get(uri);

        return respond(response, 200);

        // if (response.status === 200)
        //     return response.data;
        // else
        //     throw new Error(`Bad response status (${response.status})`);
    }

    async function _save({ resource_id, relation_item, method }) {
        // let prom = undefined;
        let uri = `/${resource}/${resource_id}/${relation}`;
        let expected_status = undefined;
        let verb = undefined;

        if (relation_item.id) {
            uri += '/' + relation_item.id;
            // expected_status = 200;
            verb = method ?? 'put';
        }
        else {
            // expected_status = 201;
            verb = method ?? 'post';
        }

        if (verb === 'post')
            expected_status = 201;
        else
            expected_status = 200;

        let response = await apiClient[verb](uri, relation_item);

        return respond(response, expected_status);

        // if (response.status === expected_status)
        //     return response.data;
        // else
        //     throw new Error(`Bad response status (${response.status})`);
    }

    async function _delete({ resource_id, relation_id }) {
        let uri = `/${resource}/${resource_id}/${relation}/${relation_id}`;
        let expected_status = 204;

        let response = await apiClient.delete(uri);

        return respond(response, expected_status);

        // if (response.status === expected_status)
        //     return response.data;
        // else
        //     throw new Error(`Bad response status (${response.status})`);
    }

    let _interface = {
        ['get' + relation_capitalized]: _get,
        ['save' + relation_unpluralized]: _save,
        ['delete' + relation_unpluralized]: _delete,
    }

    for (let i in _interface) {
        if (exceptions.includes(i))
            delete _interface[i]
    }

    return _interface
}







export {
    GenericApiResource,
    GenericApiRelationship,
}