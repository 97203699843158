import { defineStore } from 'pinia'
import * as api from '@services/api';

export default function ResourceStore(resource, additional_props = {}) {

    // const bind_func = (f, t) => {
    //     return f.bind(t);
    // }

    // const bind_func_obj = (o, t) => {
    //     for (let k in o) {
    //         o[k] = bind_func(o[k], t)
    //     }
    //     return o
    // }

    const additional_state = additional_props.state || {}
    const additional_actions = additional_props.actions || {}

    let api_resource = undefined;
    
    //console.log(api,resource);

    api_resource = api[resource];

    let resource_capitalized = resource.charAt(0).toUpperCase() + resource.slice(1);

    return defineStore(resource, {

        state: () => {
          return {
            [resource]: {},
            shadow: undefined,
            [`${resource}_list`]: [],
            ...additional_state,
          }
        },
        actions: {
          async ['get' + resource_capitalized]( id = null, options = {} ) {
            try {
              const response = await api_resource.get(id, options);

              if (id !== null)
                this[resource] = response;
              else
                this[`${resource}_list`] = response;

              return response;

            } catch (error) {
              console.log( error );
              throw error;
            }
          },
          async ['save' + resource_capitalized](aux) {
            try {
                const response = await api_resource.save({...this[resource], ...aux});
                this[resource] = response;
                this.shadow = undefined;
            } catch (error) {
                console.log( error );
                throw error;
            }
          },
          ['reset' + resource_capitalized]() {
            this[resource] = {};
            this[`${resource}_list`] = [];
            this.shadow = undefined;
          },
          toggleEdit() {
            if (typeof(this.shadow) === 'undefined')
                this.startEdit();
            else
                this.exitEdit();
          },
          startEdit() {
            this.shadow = _.cloneDeep(this[resource]);
          },
          exitEdit() {
            this[resource] = this.shadow;
            this.shadow = undefined;
          },
          ...additional_actions,
        //   ...(bind_func_obj(additional_actions, this)),
        },
    })
}

